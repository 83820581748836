import type { T3Gallery } from '@t3headless/nuxt-typo3'
import { computed } from 'vue'

const useFlatGallery = (props: { gallery: T3Gallery }) => {
  const flatGallery = computed(() => {
    if (!props.gallery?.rows) return []

    return Object.values(props.gallery.rows)
      .flatMap((row) => Object.values(row.columns))
      .map(({ properties, publicUrl }) => ({
        id: properties?.fileReferenceUid,
        publicUrl,
        properties
      }))
  })

  return flatGallery
}

export default useFlatGallery
